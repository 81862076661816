import React from 'react';

import Layout from '../components/layout';
import Hero from '../components/hero';
import SEO from '../components/seo';
import AboutUs from '../components/about-us';
import Services from '../components/services';
import Value from '../components/value';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero />

    {/* <AboutUs />
    <Services />
    <Value /> */}
  </Layout>
);

export default IndexPage;
